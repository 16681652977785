<template>
  <b-modal
    id="system-data-modal"
    ref="system-data-modal"
    title="System Data"
    size="lg"
    centered
    hide-footer
  >
    <pre v-html="systemData" />
    <div class="copy-icon-wrapper">
      <div class="tooltip-wrapper">
        <feather-icon
          icon="CopyIcon"
          size="24"
          stroke="#7367f0"
          class="cursor-pointer"
          @click="handleCopy"
        />
        <div class="tooltip b-tooltip bs-tooltip-bottom">
          <div class="arrow" />
          <div class="tooltip-inner">
            {{ isCopied ? 'Copied' : 'Copy' }}
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'SystemDataModal',
  props: {
    systemData: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isCopied: false,
    }
  },
  methods: {
    handleCopy() {
      this.isCopied = true

      navigator.clipboard.writeText(this.systemData)

      setTimeout(() => {
        this.isCopied = false
      }, 1000)
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .modal-backdrop {
  width: 100%;
  height: 100%;
}

pre {
  background-color: white;
}

.copy-icon-wrapper {
  position: absolute;
  top: 15px;
  right: 15px;
  .tooltip-wrapper {
    position: relative;
    &:hover {
      .tooltip {
        visibility: visible;
        opacity: 1;
      }
    }
    .tooltip {
      visibility: hidden;
      opacity: 0;
      transition: all 0.3s ease-in-out;
      width: max-content;
      top: 20px;
      right: 0;
      .arrow {
        right: 0;
      }
    }
  }
}
</style>
