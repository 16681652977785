<template>
  <div class="log-new-leads-table-filter-container">
    <div class="header">
      <div class="title">
        Filters
      </div>
    </div>
    <b-row
      class="filter-form"
      no-gutters
    >
      <b-col
        class="filter-item"
        cols="12"
        sm="6"
        lg="4"
      >
        <HeroVueSelect
          id="system-select"
          v-model="filter.system"
          label="System"
          :loading="system.isLoading"
          :options="system.options"
        />
      </b-col>
      <b-col
        class="filter-item"
        cols="12"
        sm="6"
        lg="2"
      >
        <HeroVueSelect
          id="status-select"
          v-model="filter.status"
          label="Status"
          :loading="status.isLoading"
          :options="status.options"
        />
      </b-col>
      <b-col
        class="filter-item"
        cols="12"
        sm="6"
        lg="3"
      >
        <b-form-group label-for="date-range">
          <template #label>
            <span style="font-size: 1rem; font-weight: 600;">
              Created At
            </span>
          </template>
          <div class="date-range-form-group">
            <date-range-picker
              v-model="filter.createdAt"
              opens="right"
              style="width: 100%"
              :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
              show-dropdowns
            >
              <template #input="picker">
                {{ picker.startDate | createdAt }} - {{ picker.endDate | createdAt }}
              </template>
            </date-range-picker>
            <div
              v-if="filter.createdAt.startDate && filter.createdAt.endDate"
              class="x-icon-wrapper"
            >
              <feather-icon
                icon="XIcon"
                class="cursor-pointer"
                @click="filter.createdAt = {
                  startDate: null,
                  endDate: null,
                }"
              />
            </div>
          </div>
        </b-form-group>
      </b-col>
      <b-col
        class="filter-item"
        cols="12"
        sm="6"
        lg="3"
      >
        <HeroInputText
          id="search"
          v-model="filter.id"
          label="ID/Lead ID"
          placeholder="ID, Lead ID..."
        />
      </b-col>
      <b-col
        class="filter-item"
        cols="12"
        sm="6"
      >
        <HeroInputText
          id="search"
          v-model="filter.systemData"
          label="System Data"
          placeholder="System Data..."
        />
      </b-col>
      <b-col class="filter-item">
        <HeroButtonAction
          variant="primary"
          type="button"
          class="btn-submit"
          :is-loading="isLoading"
          @click="$emit('submit', filter)"
        >
          Submit
        </HeroButtonAction>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'
import HeroVueSelect from '@/views/components/form/selects/HeroVueSelect.vue'
import DateRangePicker from 'vue2-daterange-picker'
import moment from 'moment'
// CSS
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import axiosInstance from '@/libs/axiosInstance'
import SweetAlert from '@/services/SweetAlert'
import ErrorService from '@/services/ErrorService'

export default {
  name: 'LogNewLeadsTableFilter',
  components: {
    DateRangePicker,
    HeroVueSelect,
    HeroButtonAction,
    HeroInputText,
  },
  filters: {
    createdAt(date) {
      return date ? moment(date).tz('Asia/Bangkok').format('DD MMMM YYYY') : ''
    },
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filter: {
        system: '',
        status: '',
        id: '',
        systemData: '',
        createdAt: {
          startDate: moment().subtract(1, 'day').tz('Asia/Bangkok').format('YYYY-MM-DD'),
          endDate: moment().tz('Asia/Bangkok').format('YYYY-MM-DD'),
        },
      },
      system: {
        isLoading: false,
        options: [],
      },
      status: {
        isLoading: false,
        options: [],
      },
    }
  },
  mounted() {
    this.getSystemDropdownOptions()
    this.getStatusDropdownOptions()
  },
  methods: {
    async getSystemDropdownOptions() {
      try {
        this.system.isLoading = true

        const url = `${process.env.VUE_APP_LEAD_SERVICE_API}/dropdown-options/system-options`
        const response = await axiosInstance.get(url, { withCredentials: false })

        this.system.options = response.data.data.map(({ name }) => ({
          text: name,
          value: name,
        }))
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      } finally {
        this.system.isLoading = false
      }
    },

    async getStatusDropdownOptions() {
      try {
        this.status.isLoading = true

        const url = `${process.env.VUE_APP_LEAD_SERVICE_API}/dropdown-options/status-options`
        const response = await axiosInstance.get(url, { withCredentials: false })

        this.status.options = response.data.data.map(({ id, name }) => ({
          text: name,
          value: id.toString(),
        }))
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      } finally {
        this.status.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.log-new-leads-table-filter-container {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 16px 12px 16px;
    .title {
      font-size: 1.125rem;
      font-weight: 600;
    }
    >button {
      width: auto;
    }
  }
  .filter-form {
    row-gap: 10px;
    padding: 0 10px 16px 10px;
    .filter-item {
      padding: 0 6px 0 6px;
      .form-group {
        margin-bottom: 0;
      }
      &:last-child {
        align-self: flex-end;
      }
      .btn-submit {
        max-width: 120px;
        margin-left: auto;
      }
    }
    .date-range-form-group {
      position: relative;
      max-height: 38px;
      ::v-deep.daterangepicker.show-ranges {
        @media(min-width: 768px) {
          min-width: unset;
        }
        @media(min-width: 769px) {
          min-width: 683px;
        }
      }
      .x-icon-wrapper {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        height: 37.98px;
        width: 43px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>
