<template>
  <div class="log-new-leads-container">
    <b-card-actions
      ref="cardFilter"
      no-actions
      no-body
    >
      <LogNewLeadsTableFilter
        :is-loading="$refs.cardTable && $refs.cardTable.showLoading"
        @submit="submitFilter"
      />
    </b-card-actions>
    <b-card-actions
      ref="cardTable"
      no-actions
      no-body
    >
      <b-table
        show-empty
        sticky-header
        responsive
        :items="items"
        :fields="tableConfig.fields"
        :per-page="0"
        :sort-by="tableConfig.sortBy"
        :sort-desc="tableConfig.sortDirection === 'desc'"
        :sort-direction="tableConfig.sortDirection"
        no-sort-reset
        no-local-sorting
        class="log-new-leads-table"
        @sort-changed="onSortChanged"
      >
        <template #head(system_data)="{ label }">
          <div class="d-flex align-items-center col-gap-2">
            <span style="width: 134.35px">
              {{ label }}
            </span>
            <div
              v-if="items.length"
              class="tooltip-wrapper"
            >
              <feather-icon
                icon="CopyIcon"
                size="18"
                class="cursor-pointer"
                @click="handleCopyAll"
              />
              <div class="tooltip b-tooltip bs-tooltip-bottom">
                <div class="arrow" />
                <div class="tooltip-inner">
                  {{ isCopied ? 'Copied all' : 'Copy all' }}
                </div>
              </div>
            </div>
          </div>
        </template>

        <template #cell(system_data)="{ item }">
          <div class="d-flex align-items-center col-gap-2">
            <span
              class="text-show-more-details"
              @click="showSystemDataModal(item.system_data)"
            >
              Show more details
            </span>
            <div class="tooltip-wrapper">
              <feather-icon
                icon="CopyIcon"
                size="18"
                class="cursor-pointer"
                @click="handleCopy(item.system_data)"
              />
              <div class="tooltip b-tooltip bs-tooltip-bottom">
                <div class="arrow" />
                <div class="tooltip-inner">
                  {{ isCopied ? 'Copied' : 'Copy' }}
                </div>
              </div>
            </div>
          </div>
        </template>

        <template #cell(created_at)="{ item }">
          {{ formatEnglishDateTimeDefault(item.created_at) }}
        </template>

        <template #cell(updated_at)="{ item }">
          {{ formatEnglishDateTimeDefault(item.updated_at) }}
        </template>
      </b-table>
      <div class="d-flex align-items-center justify-content-end flex-wrap mx-0 mb-1 px-1 row-gap-3">
        <div class="d-flex align-items-center">
          <HeroTablePerPage
            v-model="tableConfig.perPage"
            :options="tableConfig.perPageOptions"
            class="mb-0"
            @input="debounce(getLogNewLeads)()"
          >
            <template #label>
              <div />
            </template>
          </HeroTablePerPage>
          <HeroTableStatus :per-page="tableConfig.perPage" :total-rows="tableConfig.totalRows" />
        </div>
      </div>
    </b-card-actions>

    <SystemDataModal :system-data="currentSystemData" />
  </div>
</template>

<script>
import LogNewLeadsTableFilter from '@/views/log-new-leads/components/LogNewLeadsTableFilter.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import moment from 'moment/moment'
import HeroTableStatus from '@/views/components/table/HeroTableStatus.vue'
import HeroTablePerPage from '@/views/components/table/HeroTablePerPage.vue'
import debounce from '@/mixins/debounce'
import SystemDataModal from '@/views/log-new-leads/components/SystemDataModal.vue'
import DataFormatService from '@/services/DataFormatService'
import SweetAlert from '@/services/SweetAlert'
import ErrorService from '@/services/ErrorService'
import axiosInstance from '@/libs/axiosInstance'

export default {
  name: 'LogNewLeads',
  components: {
    SystemDataModal,
    HeroTablePerPage,
    HeroTableStatus,
    BCardActions,
    LogNewLeadsTableFilter,
  },
  mixins: [debounce],
  data() {
    return {
      isCopied: false,
      currentSystemData: '',
      filter: {
        system: '',
        status: '',
        id: '',
        systemData: '',
        createdAt: {
          startDate: moment().subtract(1, 'day').tz('Asia/Bangkok').format('YYYY-MM-DD'),
          endDate: moment().tz('Asia/Bangkok').format('YYYY-MM-DD'),
        },
      },
      items: [],
      tableConfig: {
        currentPage: 1,
        perPage: 100,
        perPageOptions: [5, 10, 25, 50, 75, 100, 200, 300, 400, 500],
        totalRows: 0,
        sortBy: 'id',
        sortDirection: 'desc',
        timeInterval: moment(),
        fields: [
          {
            label: 'No.',
            key: 'no',
          },
          {
            label: 'ID',
            key: 'id',
            sortable: true,
          },
          {
            label: 'LEAD ID',
            key: 'lead_id',
            sortable: true,
          },
          {
            label: 'CREATED AT',
            key: 'created_at',
            sortable: true,
            thStyle: { minWidth: '210px' },
          },
          {
            label: 'SYSTEM',
            key: 'system',
            sortable: true,
          },
          {
            label: 'SYSTEM DATA',
            key: 'system_data',
          },
        ],
      },
    }
  },
  computed: {
    hasSomeFilters() {
      const {
        system, status, createdAt, id, systemData,
      } = this.filter
      const hasDateRange = createdAt.startDate && createdAt.endDate

      return system || status || hasDateRange || id || systemData
    },
  },
  mounted() {
    const htmlElement = document.querySelector('html')
    htmlElement.style.zoom = '80%'

    this.getLogNewLeads()
  },
  beforeDestroy() {
    const htmlElement = document.querySelector('html')
    htmlElement.style.zoom = '100%'
  },
  methods: {
    formatEnglishDateTimeDefault: DataFormatService.formatEnglishDateTimeDefault,
    isJSON(value) {
      try {
        JSON.parse(value)
      } catch (_) {
        return false
      }
      return true
    },

    formattedSystemData(systemData) {
      let parsedBodyData = {}

      if (this.isJSON(systemData)) {
        parsedBodyData = JSON.parse(systemData)
        return JSON.stringify(parsedBodyData, null, 2)
      }

      const [headerPart, bodyPart] = systemData.trim().split('\r\n\r\n')

      if (this.isJSON(bodyPart)) {
        parsedBodyData = JSON.parse(bodyPart)
        return `${headerPart}\n\n${JSON.stringify(parsedBodyData, null, 2)}`
      }

      const urlParams = new URLSearchParams(bodyPart)

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of urlParams.entries()) {
        if (key === 'data.json') {
          parsedBodyData[key] = JSON.parse(value)
        } else {
          parsedBodyData[key] = value
        }
      }

      return `${headerPart}\n\n${JSON.stringify(parsedBodyData, null, 2)}`
    },

    handleCopy(systemData) {
      this.isCopied = true

      navigator.clipboard.writeText(this.formattedSystemData(systemData))

      setTimeout(() => {
        this.isCopied = false
      }, 1000)
    },

    handleCopyAll() {
      this.isCopied = true

      const formattedAllSystemData = this.items.map(item => this.formattedSystemData(item.system_data))
      navigator.clipboard.writeText(formattedAllSystemData.join('\n\n'))

      setTimeout(() => {
        this.isCopied = false
      }, 1000)
    },

    showSystemDataModal(systemData) {
      this.currentSystemData = this.formattedSystemData(systemData)
      this.$bvModal.show('system-data-modal')
    },

    onSortChanged(ctx) {
      const { sortBy, sortDesc } = ctx
      this.tableConfig.sortBy = sortBy
      this.tableConfig.sortDirection = sortDesc ? 'desc' : 'asc'
      this.getLogNewLeads()
    },

    submitFilter(filter) {
      this.filter = { ...filter }

      if (!this.hasSomeFilters) {
        this.$swal({ ...SweetAlert.info, text: 'Please use some filters from System, Status, Created At, or Search field before submitting.' })
        return
      }

      this.getLogNewLeads()
    },

    async getLogNewLeads() {
      try {
        this.$refs.cardTable.showLoading = true

        const {
          system, status, id, systemData, createdAt: { startDate, endDate },
        } = this.filter
        const {
          perPage, sortBy, sortDirection,
        } = this.tableConfig

        const url = `${process.env.VUE_APP_LEAD_SERVICE_API}/load-log-new-leads`
        const response = await axiosInstance.get(url, {
          params: {
            system,
            ...status && { status },
            startDate: startDate ? moment(startDate).tz('Asia/Bangkok').format('YYYY-MM-DD') : null,
            endDate: endDate ? moment(endDate).tz('Asia/Bangkok').format('YYYY-MM-DD') : null,
            id: id || null,
            q: systemData || null,
            limit: perPage,
            orderByColumn: sortBy,
            orderByDirection: sortDirection,
          },
          withCredentials: false,
        })

        this.items = response.data.data.map((item, index) => ({
          ...item,
          no: index + 1,
          lead_id: item.lead_id || '',
        }))
        this.tableConfig.totalRows = this.items.length
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

        this.items = []
        this.tableConfig.totalRows = 0
      } finally {
        if (this.$refs.cardTable) {
          this.$refs.cardTable.showLoading = false
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/variables';

.tooltip-wrapper {
  position: relative;
  &:hover {
    .tooltip {
      visibility: visible;
      opacity: 1;
    }
  }
  .tooltip {
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    width: max-content;
    top: 16px;
    right: 0;
    .arrow {
      right: 0;
    }
  }
}

.log-new-leads-container {
  .log-new-leads-table {
    max-height: calc(100vh - 50px);
    @media(min-width: 576px) {
      max-height: 100vh;
    }
    @media(min-width: 768px) {
      max-height: calc(100vh - 320px);
    }
    @media(min-width: 992px) {
      max-height: calc(100vh - 248px);
    }

    .text-show-more-details {
      font-weight: 500;
      color: $primary;
      text-decoration: underline;
      cursor: pointer;
      flex: 0 0 auto;
    }
  }
}
</style>
