var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"log-new-leads-container"},[_c('b-card-actions',{ref:"cardFilter",attrs:{"no-actions":"","no-body":""}},[_c('LogNewLeadsTableFilter',{attrs:{"is-loading":_vm.$refs.cardTable && _vm.$refs.cardTable.showLoading},on:{"submit":_vm.submitFilter}})],1),_c('b-card-actions',{ref:"cardTable",attrs:{"no-actions":"","no-body":""}},[_c('b-table',{staticClass:"log-new-leads-table",attrs:{"show-empty":"","sticky-header":"","responsive":"","items":_vm.items,"fields":_vm.tableConfig.fields,"per-page":0,"sort-by":_vm.tableConfig.sortBy,"sort-desc":_vm.tableConfig.sortDirection === 'desc',"sort-direction":_vm.tableConfig.sortDirection,"no-sort-reset":"","no-local-sorting":""},on:{"sort-changed":_vm.onSortChanged},scopedSlots:_vm._u([{key:"head(system_data)",fn:function(ref){
var label = ref.label;
return [_c('div',{staticClass:"d-flex align-items-center col-gap-2"},[_c('span',{staticStyle:{"width":"134.35px"}},[_vm._v(" "+_vm._s(label)+" ")]),(_vm.items.length)?_c('div',{staticClass:"tooltip-wrapper"},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"CopyIcon","size":"18"},on:{"click":_vm.handleCopyAll}}),_c('div',{staticClass:"tooltip b-tooltip bs-tooltip-bottom"},[_c('div',{staticClass:"arrow"}),_c('div',{staticClass:"tooltip-inner"},[_vm._v(" "+_vm._s(_vm.isCopied ? 'Copied all' : 'Copy all')+" ")])])],1):_vm._e()])]}},{key:"cell(system_data)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-center col-gap-2"},[_c('span',{staticClass:"text-show-more-details",on:{"click":function($event){return _vm.showSystemDataModal(item.system_data)}}},[_vm._v(" Show more details ")]),_c('div',{staticClass:"tooltip-wrapper"},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"CopyIcon","size":"18"},on:{"click":function($event){return _vm.handleCopy(item.system_data)}}}),_c('div',{staticClass:"tooltip b-tooltip bs-tooltip-bottom"},[_c('div',{staticClass:"arrow"}),_c('div',{staticClass:"tooltip-inner"},[_vm._v(" "+_vm._s(_vm.isCopied ? 'Copied' : 'Copy')+" ")])])],1)])]}},{key:"cell(created_at)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatEnglishDateTimeDefault(item.created_at))+" ")]}},{key:"cell(updated_at)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatEnglishDateTimeDefault(item.updated_at))+" ")]}}])}),_c('div',{staticClass:"d-flex align-items-center justify-content-end flex-wrap mx-0 mb-1 px-1 row-gap-3"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('HeroTablePerPage',{staticClass:"mb-0",attrs:{"options":_vm.tableConfig.perPageOptions},on:{"input":function($event){_vm.debounce(_vm.getLogNewLeads)()}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div')]},proxy:true}]),model:{value:(_vm.tableConfig.perPage),callback:function ($$v) {_vm.$set(_vm.tableConfig, "perPage", $$v)},expression:"tableConfig.perPage"}}),_c('HeroTableStatus',{attrs:{"per-page":_vm.tableConfig.perPage,"total-rows":_vm.tableConfig.totalRows}})],1)])],1),_c('SystemDataModal',{attrs:{"system-data":_vm.currentSystemData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }